import { useState, useEffect } from 'react';

const SECOND_BY_MILLISECONDS = 1_000;
const MINUTE_TIME = SECOND_BY_MILLISECONDS * 60;
const HOUR_TIME = MINUTE_TIME * 60;
const DAY_TIME = HOUR_TIME * 24;

function useTimer(deadline, interval = SECOND_BY_MILLISECONDS) {
  const [timespan, setTimespan] = useState(deadline - Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimespan(timespan => timespan - interval);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);

  /* This is effect when the initial deadline value changes */
  useEffect(() => {
    setTimespan(new Date(deadline) - Date.now());
  }, [deadline]);

  return {
    days: Math.floor(timespan / DAY_TIME),
    hours: Math.floor((timespan / HOUR_TIME) % 24),
    minutes: Math.floor((timespan / MINUTE_TIME) % 60),
    seconds: Math.floor((timespan / SECOND_BY_MILLISECONDS) % 60),
  };
}

export default useTimer;
