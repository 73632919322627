import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PlanPage from 'pages/planPage';
import StartPage from 'pages/startPage';

function App() {
  const [formData, setFormData] = useState({});

  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage formData={formData} setFormData={setFormData} />} />
        <Route path="/optimization-plan" element={<PlanPage />} />
      </Routes>
    </Router>
  );
}

export default App;
