import { useState } from 'react';

import Start from 'pages/start';
import Questions from 'pages/questons';

function StartPage({ formData, setFormData }) {
  const [gender, setGender] = useState('');

  return gender ? (
    <Questions gender={gender} formData={formData} setFormData={setFormData} />
  ) : (
    <Start setGender={setGender} />
  );
}

export default StartPage;
