import { memo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { QUESTIONS } from 'constants/steps';
import FormHeader from 'sections/formHeader';
import Button from 'components/button';
import PolicyBlock from 'components/policyBlock';
import SingleOption from 'components/singleOption';

import 'swiper/css';
import 'swiper/css/pagination';

import './style.scss';

const Questions = ({ gender, formData, setFormData }) => {
  const swiperRef = useRef(null);

  const [parentStep, setParentStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [totalStep, setTotalStep] = useState(0);
  const [doneSteps, setDoneSteps] = useState([]);
  const [policySelected, setPolicySelected] = useState(false);
  const [showPolicyWarning, setShowPolicyWarning] = useState(0);

  const goToNextSlide = () => {
    const currentSubArray = QUESTIONS[parentStep];

    if (subStep === 0) {
      setDoneSteps(prev => [...new Set([...prev, parentStep])]);
    }

    if (subStep < currentSubArray.length - 1) {
      setSubStep(prev => prev + 1);
    } else if (parentStep < QUESTIONS.length - 1) {
      setParentStep(prev => prev + 1);
      setSubStep(0);
    }

    setTotalStep(prev => prev + 1);
    swiperRef.current?.slideNext();
  };

  const goToPrevSlide = () => {
    if (subStep > 0) {
      setSubStep(prev => prev - 1);
    } else if (parentStep > 0) {
      setParentStep(prev => prev - 1);
      setSubStep(QUESTIONS[parentStep - 1].length - 1);
      setDoneSteps(prev => prev.filter(step => step !== parentStep));
    }

    setTotalStep(prev => prev - 1);
    swiperRef.current?.slidePrev();
  };

  const handlePolicy = () => {
    setPolicySelected(prev => {
      setShowPolicyWarning(prev ? Date.now : 0);

      return !prev;
    });

    if (!policySelected && formData['goal-1']) {
      setTimeout(goToNextSlide, 400);
      setShowPolicyWarning(0);
    }
  };

  const handleSelected = ({ key, value, checkbox }) => {
    setFormData(prev => {
      if (checkbox) {
        if (prev[key]?.includes(value)) {
          return { ...prev, [key]: prev[key].filter(item => item !== value) };
        } else {
          return { ...prev, [key]: [...(prev[key] || []), value] };
        }
      } else {
        return { ...prev, [key]: value };
      }
    });

    if (!checkbox && policySelected) {
      setTimeout(goToNextSlide, 600); // Run goToNextSlide after 2 seconds
    }

    if (!policySelected) {
      setShowPolicyWarning(Date.now);
    }
  };

  console.log(formData, policySelected);
  return (
    <div className="formWrapper">
      <FormHeader
        name="test"
        parentStep={parentStep}
        subStep={subStep}
        totalStep={totalStep}
        doneSteps={doneSteps}
        onPrev={goToPrevSlide}
      />
      <Swiper
        speed={500}
        longSwipes
        className="formSwiper"
        allowTouchMove={false}
        onSwiper={swiper => (swiperRef.current = swiper)}
      >
        {QUESTIONS.map((steps, parentIndex) =>
          steps.map(({ key, title, buttonText, checkbox, content, options = [] }, subIndex) => (
            <SwiperSlide key={`${parentIndex}-${subIndex}`}>
              <div className="questionContent">
                <p className="title">{title}</p>
                {content && <p className="description">{content}</p>}
                <div className="optionBlock">
                  {options.map(({ label, image, value, description }, optionIndex) => (
                    <SingleOption
                      label={label}
                      key={optionIndex}
                      checkbox={checkbox}
                      image={image?.[gender]}
                      description={description}
                      isSelected={formData?.[key]?.includes(value)}
                      onClick={() => handleSelected({ key, value, checkbox })}
                    >
                      {label}
                    </SingleOption>
                  ))}
                </div>
                {parentIndex + subIndex === 0 && (
                  <PolicyBlock
                    onClick={handlePolicy}
                    selected={policySelected}
                    showWarning={showPolicyWarning}
                  />
                )}
                {((checkbox && formData?.[key]?.length > 0) || !options.length) && (
                  <div className="actionBlock">
                    <Button onClick={goToNextSlide}>{buttonText || 'Continue'}</Button>
                  </div>
                )}
              </div>
            </SwiperSlide>
          )),
        )}
      </Swiper>
    </div>
  );
};

export default memo(Questions);
