import { memo } from 'react';
import clsx from 'clsx';
import TextTransition, { presets } from 'react-text-transition';

import './style.scss';
import { QUESTIONS, STEP_HEADER } from 'constants/steps';

const FormHeader = ({ onPrev, parentStep, subStep, doneSteps, totalStep }) => {
  const { title } = STEP_HEADER.find(({ steps }) => steps.includes(totalStep)) || {};

  const calculateProgressForCurrentStep = currentParentStep => {
    if (currentParentStep !== parentStep) {
      return doneSteps.includes(currentParentStep) ? 100 : 0;
    }
    const currentSubArray = QUESTIONS[currentParentStep];
    if (!currentSubArray) return 0;

    const currentStepCount = currentSubArray.length;
    return (subStep / currentStepCount) * 100;
  };

  return (
    <div className="formHeader">
      <i className="icon-icon gymdoneLogo" />
      <TextTransition inline className="title" springConfig={presets.gentle}>
        {title}
      </TextTransition>
      <div className={clsx('progressAndBack', { show: totalStep > 0 })}>
        <div className="backButton" onClick={onPrev}>
          <i className="icon-arrow-left" />
        </div>
        <div className="progressWrapper">
          {Array.from({ length: QUESTIONS.length - 1 }).map((_, parentIndex) => {
            const progressForParent = calculateProgressForCurrentStep(parentIndex);

            return (
              <div className="lineWrapper">
                <div
                  key={parentIndex}
                  className="line"
                  style={{ width: `${progressForParent}%` }}
                />
              </div>
            );
          })}
          <div className="dotsWrapper">
            {Array.from({ length: QUESTIONS.length }).map((_, parentIndex) => (
              <div key={parentIndex} className={clsx('dot', { done: parentIndex <= parentStep })} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FormHeader);
