import { memo } from 'react';
import clsx from 'clsx';

import './style.scss';

const Check = ({ selected, circle }) => {
  return (
    <div className={clsx('checkBox', { circle, selected })}>
      <i className="icon-check" />
    </div>
  );
};

export default memo(Check);
