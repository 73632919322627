import { memo } from 'react';
import clsx from 'clsx';

import Check from 'components/check';

import './style.scss';

const SingleOption = ({ onClick, image, checkbox, description, label, isSelected }) => {
  return (
    <div className={clsx('singleOption', { selected: isSelected })} onClick={onClick}>
      {checkbox && <Check selected={isSelected} />}
      <p className="label">{label}</p>
      {description && <p className="description">{description}</p>}
      {image && (
        <div className="imageBlock">
          <img src={image} alt={label} />
        </div>
      )}
    </div>
  );
};

export default memo(SingleOption);
