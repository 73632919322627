import { useState } from 'react';
import clsx from 'clsx';
import images from 'react-payment-inputs/images';
import { usePaymentInputs } from 'react-payment-inputs';

import Timer from 'components/timer';
import Check from 'components/check';
import Button from 'components/button';

import beforeAfterBg from 'assets/images/plan/ba-bg.png';
import nowMale from 'assets/images/plan/nowMale.png';
import afterMale from 'assets/images/plan/afterMale.png';

import './style.scss';

const data = {
  beforeAfter: {
    now: {
      img: nowMale,
      fat: '15-19%',
      mass: 2,
    },
    after: {
      img: afterMale,
      fat: '10-14%',
      mass: 4,
    },
  },
};

const PLAN_OPTIONS = [
  {
    id: 1,
    title: '1 week plan',
    priceDay: '1.14',
    pricePrev: '15.99',
    price: '7.99',
    range: 'first week',
  },
  {
    id: 2,
    title: '4 week plan',
    priceDay: '0.63',
    pricePrev: '15.99',
    price: '7.99',
    range: 'first week',
    isPopular: true,
  },
  {
    id: 3,
    title: '12 week plan',
    priceDay: '0.33',
    pricePrev: '15.99',
    price: '7.99',
    range: 'first week',
  },
];

const countdownTime = Date.now() + 10 * 60 * 1000;

const PlanPage = () => {
  const [cvc, setCvc] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(3);

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } =
    usePaymentInputs();
  console.log(getCardImageProps);
  const current = PLAN_OPTIONS[selectedPlan - 1];

  return (
    <div className="planPage">
      <div className="beforeAfterBlock">
        <div className="header">
          <p>Now</p>
          <p>After Program</p>
        </div>
        <div className="imageBlock">
          <img src={beforeAfterBg} alt="bg" className="bg" />
          <img src={data.beforeAfter.now.img} alt="now" className="body" />
          <img src={data.beforeAfter.after.img} alt="now" className="body" />
        </div>
        <div className="info">
          <div className="sub">
            <p className="title">Body fat</p>
            <p className="detail">{data.beforeAfter.now.fat}</p>
          </div>
          <div className="sub">
            <p className="title">Body fat</p>
            <p className="detail">{data.beforeAfter.after.fat}</p>
          </div>
        </div>
        <div className="info">
          <div className="sub">
            <hr />
          </div>
          <div className="sub">
            <hr />
          </div>
        </div>
        <div className="info">
          <div className="sub">
            <p className="title">Muscle mass</p>
            <div className="massDetail">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={clsx('line', { active: index + 1 <= data.beforeAfter.now.mass })}
                />
              ))}
            </div>
          </div>
          <div className="sub">
            <p className="title">Muscle mass</p>
            <div className="massDetail">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={clsx('line', { active: index + 1 <= data.beforeAfter.after.mass })}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="guaranteeBlock">
        <i className="icon-square-exclamation" /> This is not a guarantee or promise of results.
      </div>
      <div className="reservedDiscount">
        <div className="timerBlock">
          <p className="title">Discount reserved for:</p>
          <Timer deadline={countdownTime} />
        </div>
        <Button isPrimary isSmall>
          Get My Plan
        </Button>
      </div>
      <div className="aiBlock">
        <h2>
          Your <b>AI-Optimized</b> Plan
        </h2>
        <div className="content">
          <div className="aiBadge">
            <i className="icon-stars" />
            AI Generated
          </div>
          <div className="single">
            <i className="icon-goal" />
            <div className="info">
              <p className="title">Your Goal</p>
              <p className="value">Gain Muscle</p>
            </div>
          </div>
          <div className="single">
            <i className="icon-duration" />
            <div className="info">
              <p className="title">Duration</p>
              <p className="value">12 weeks</p>
            </div>
          </div>
          <div className="single">
            <i className="icon-wight" />
            <div className="info">
              <p className="title">Target weight</p>
              <p className="value">50kg</p>
            </div>
          </div>
          <div className="single">
            <i className="icon-level3" />
            <div className="info">
              <p className="title">Your Level</p>
              <p className="value">Intermediate</p>
            </div>
          </div>
        </div>
      </div>
      <div className="promoBlock">
        <h2>Reach your goals with your personalized workout plan!</h2>
        <div className="content">
          <div className="leftSide">
            <p>Applied limited discount</p>
            <div className="promo">
              <div className="percentBadge">
                <b>33%</b> 50% off
              </div>
              <p className="line">Newcomer_sep24</p>
              <p className="newPromo">lastcall_sep24</p>
            </div>
          </div>
          <div className="rightSide">
            <p>Applicable for</p>
            <div className="timerBlock">
              <Timer deadline={countdownTime} withName />
            </div>
          </div>
        </div>
      </div>
      <div className="planOptions">
        {PLAN_OPTIONS.map(({ id, title, isPopular, price, range, pricePrev, priceDay }) => (
          <div
            key={id}
            onClick={() => setSelectedPlan(id)}
            className={clsx('singlePlan', { selected: selectedPlan === id })}
          >
            <Check circle selected={selectedPlan === id} />
            <div className="info">
              {isPopular && (
                <div className="popular">
                  <i className="icon-hot" />
                  Most Popular
                </div>
              )}
              <p className="title">{title}</p>
              <p className="priceDay">${priceDay}/per day</p>
            </div>
            <div className="priceBlock">
              <p className="prevPrice">${pricePrev}</p>
              <p className="price">${price}</p>
              <p className="range">{range}</p>
            </div>
          </div>
        ))}
        <p className="moneyBackInfo">30-day money-back guarantee</p>
        <Button isPrimary>Get My Plan</Button>
        <ul className="subscriptionInfo">
          <li>This is an automatically renewing subscription.</li>
          <li>Today, Zing will charge you $18.99 as an introductory offer.</li>
          <li>
            Your introductory offer lasts until October 17th, 2024. You can cancel before this date
            without additional charges.
          </li>
          <li>
            If you don't cancel, your payment method will be automatically charged the full price of
            $37.99 every month until you cancel the subscription.
          </li>
          <li>
            You can cancel the subscription at any time through the Zing app settings or by
            contacting support at <a href="/">support@zing.coach</a> .
          </li>
          <li>
            This subscription is subject to Zing's <a href="/">Privacy Policy</a>,{' '}
            <a href="/">Terms of Use</a>, and <a href="/">Refund Policy</a>.
          </li>
        </ul>
      </div>
      <div className="paymentForm">
        <h2>Payment method</h2>
        <div className="form">
          <div className="totalBlock">
            <p className="title">{current.title}</p>
            <ul className="steps">
              <li>
                <p>${current.price} for 7 days</p>
                <p className="when">Starting today</p>
              </li>
              <li>
                <p>${current.pricePrev} every 7 days</p>
                <p className="when">Starting on Sep 26, 2024</p>
              </li>
            </ul>
            <div className="total">
              Total today
              <p>
                <b>{current.price} USD</b> per 7 days
              </p>
            </div>
            <div className="dataBlock">
              <svg {...getCardImageProps({ images })} />
              <input
                {...getCardNumberProps({ onChange: ({ target }) => setCardNumber(target.value) })}
                value={cardNumber}
              />
              <div className="twoColumn">
                <input
                  {...getExpiryDateProps({ onChange: ({ target }) => setExpiryDate(target.value) })}
                  value={expiryDate}
                />
                <input
                  {...getCVCProps({ onChange: ({ target }) => setCvc(target.value) })}
                  value={cvc}
                />
              </div>
              {meta.isTouched && meta.error && <p>Error: {meta.error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanPage;
