import clsx from 'clsx';

import Check from 'components/check';

import './style.scss';

const PolicyBlock = ({ showWarning, selected, onClick }) => {
  return (
    <div
      onClick={() => onClick({ key: 'policy', value: true })}
      className={clsx('policyBlock', { warning: showWarning })}
    >
      <p className="warningInfo">To continue, please accept our Privacy Policy</p>
      <Check selected={selected} circle />
      <div className="info">
        <p>
          You consent to the processing of your health data to be provided with Zing Coach web and
          app services.
        </p>
        <p>
          Read more in the{' '}
          <a href="https://karl.am" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default PolicyBlock;
