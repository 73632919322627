import { memo, useEffect, useState } from 'react';

import headerImage from 'assets/images/start/header.png';
import colorsImage from 'assets/images/start/colors.png';

import './style.scss';
import clsx from 'clsx';

const Start = ({ setGender }) => {
  const [button, setButton] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleGender = gender => {
    setButton(gender);

    setTimeout(() => setGender(gender), 700);
  };

  useEffect(() => {
    const randomDuration = Math.floor(Math.random() * (6000 - 4000 + 1)) + 4000;
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 110) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 1;
      });
    }, randomDuration / 100);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="startWrapper">
      <div className="headerImageWrapper">
        <img src={headerImage} alt="header" />
      </div>
      <div className="infoContent">
        <div className="logoBlock">
          <i className="icon-icon" /> <img src={colorsImage} alt="colors" />
        </div>
        <h1>See How Fast You Can Hit Your Fitness Goals with GYMDONE</h1>
        <h2>
          Get a new you in 12 weeks.
          <br />
          Take the FREE body type quiz to get your personalized plan.
        </h2>

        {loading ? (
          <div className="startProgressWrapper">
            <div className="progressBlock">
              <p>{progress < 100 ? progress : 100}%</p>
              <div className="line" style={{ width: `${progress + 3}%` }} />
            </div>
          </div>
        ) : (
          <div className="genderBlock">
            <div
              onClick={() => handleGender('female')}
              className={clsx('genderButton female', { active: button === 'female' })}
            >
              <i className="icon-girl" />
              Female
            </div>
            <div
              onClick={() => handleGender('male')}
              className={clsx('genderButton male', { active: button === 'male' })}
            >
              <i className="icon-boy" />
              Male
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Start);
