import muscleGainFemale from 'assets/images/questions/1-1-female.png';
import fitterFemale from 'assets/images/questions/1-2-female.png';
import muscleMassFemale from 'assets/images/questions/1-3-female.png';
import muscleGainMale from 'assets/images/questions/1-1-male.png';
import fitterMale from 'assets/images/questions/1-2-male.png';
import muscleMassMale from 'assets/images/questions/1-3-male.png';

const imageMappings = {};
const gender = 'female';

export const QUESTIONS = [
  [
    {
      key: 'goal-1',
      title: 'What is your fitness goal?',
      content:
        'Get a new you in 12 weeks. Take the FREE body type quiz to get your personalized plan.',
      options: [
        {
          label: 'Muscle Gain',
          value: 'muscleGain',
          image: {
            female: muscleGainFemale,
            male: muscleGainMale,
          },
        },
        {
          label: 'Get Fitter',
          value: 'weightLoss',
          image: {
            female: fitterFemale,
            male: fitterMale,
          },
        },
        {
          label: 'Gain muscle mass',
          value: 'gainMuscle',
          image: {
            female: muscleMassFemale,
            male: muscleMassMale,
          },
        },
      ],
    },
    {
      key: 'target-1',
      title: 'What is your target body?',
      content:
        'Success looks different to everyone, but setting your sights on a clear target will help you stay motivated and accountable.',
      options: [
        { label: 'Lean', value: 'lean', image: imageMappings.logo },
        { label: 'Toned', value: 'toned', image: imageMappings.logo },
        { label: 'Fit', value: 'fit', image: imageMappings.logo },
      ],
    },
    {
      key: 'motivation-1',
      title: 'What motivates you to exercise?',
      content: 'With the right motivation, you’re much more likely to reach your fitness goals.',
      options: [
        { label: 'Improving Health', value: 'improving_health' },
        {
          label: 'Boosting Immune System',
          value: 'boosting_immune_system',
        },
        { label: 'Looking Better', value: 'looking_better' },
        {
          label: 'Building Strength and Endurance',
          value: 'strength_and_endurance',
        },
        { label: 'Loose Postpartum Body Weight', value: 'loose_weight' },
      ],
      checkbox: true,
    },
    {
      key: 'goal-2',
      title: 'What is your fitness goal?',
      content: "Choose what's motivating you the most right now",
      options: [
        {
          label: 'Improving physical appearance',
          value: 'ImprovingPhysicalAppearance',
        },
        {
          label: 'Enjoying more time with loved once',
          value: 'enjoyingTime',
        },
        { label: 'Getting healthier', value: 'gettingHealthier' },
        { label: 'Getting happier', value: 'gettingHappier' },
        { label: 'Other', value: 'other' },
      ],
      checkbox: true,
    },
    {
      key: 'fitness-journey',
      title: 'Zing has truly transformed my fitness journey',
      content:
        "This AI-generated workout app has become my ultimate companion,  It's not just an app; it's a coach, a friend, and a beacon of  inspiration. Thank you, Zing, for empowering me to become the strongest  version of myself. Rivky.",
      imageUrl: `${imageMappings.fitnessJourney}`,
      buttonText: 'Sounds Great',
    },
    // {
    //   key: 'physique-1',
    //   title: 'What type of physique are you aiming for?',
    //   content: 'Success looks different to everyone, but setting your sights on a clear target will help you stay motivated and accountable.',
    //   options: [
    //     { label: 'Lean', value: 'lean', image: lean, description:'Visible musculature, some lean mass' },
    //     { label: 'Muscular', value: 'muscular', image: muscular, description:'Prominent musculature and lean mass' },
    //     { label: 'Ripped', value: 'ripped', image: ripped, description:'Extensive musculature, high lean mass' },
    //   ],
    // },
    {
      key: 'focus-areas',
      title: 'Which areas would you like to focus on?',
      options:
        gender === 'female'
          ? [
              {
                label: 'Full Body',
                value: 'fullBody',
                image: imageMappings.bodyFemale,
              },
              {
                label: 'Shoulders',
                value: 'shoulders',
                image: imageMappings.shouldersFemale,
              },
              {
                label: 'Arms',
                value: 'arms',
                image: imageMappings.armsFemale,
              },
              {
                label: 'Back',
                value: 'back',
                image: imageMappings.backFemale,
              },
              {
                label: 'Chest',
                value: 'chest',
                image: imageMappings.chestFemale,
              },
              {
                label: 'Belly',
                value: 'belly',
                image: imageMappings.bellyFemale,
              },
              {
                label: 'Butt',
                value: 'butt',
                image: imageMappings.buttFemale,
              },
              {
                label: 'Legs',
                value: 'legs',
                image: imageMappings.legsFemale,
              },
            ]
          : [
              {
                label: 'Full Body',
                value: 'fullBody',
                image: imageMappings.bodyMale,
              },
              {
                label: 'Shoulders',
                value: 'shoulders',
                image: imageMappings.shouldersMale,
              },
              {
                label: 'Arms',
                value: 'arms',
                image: imageMappings.armsMale,
              },
              {
                label: 'Back',
                value: 'back',
                image: imageMappings.backMale,
              },
              {
                label: 'Chest',
                value: 'chest',
                image: imageMappings.chestMale,
              },
              {
                label: 'Belly',
                value: 'belly',
                image: imageMappings.bellyMale,
              },
              {
                label: 'Butt',
                value: 'butt',
                image: imageMappings.buttMale,
              },
              {
                label: 'Legs',
                value: 'legs',
                image: imageMappings.legsMale,
              },
            ],
      checkbox: true,
    },
    {
      key: 'fitness-success',
      title: 'Which of these is the best measure of fitness success?',
      content: 'Choose up to 3 options that are most important for tracking your progress.',
      options: [
        {
          label: 'Muscle gain',
          value: 'muscleGain',
          description: 'Building muscle and size.',
        },
        {
          label: 'Increased strength',
          value: 'increasedStrength',
          description: 'Lifting more weight.',
        },
        {
          label: 'Improved endurance',
          value: 'improvedEndurance',
          description: 'Achieving a lower body fat percentage.',
        },
        {
          label: 'Weight loss',
          value: 'weightLoss',
          description: 'Seeing lower numbers when you step on a scale.',
        },
        {
          label: 'Body measurements',
          value: 'bodyMeasurements',
          description: 'Tracking changes in your body size and shape.',
        },
        {
          label: 'Fitness Level improvements',
          value: 'fitnessLevelImprovements',
          description: 'Progress in overall fitness and well-being.',
        },
      ],
      checkbox: true,
      focus: true,
    },
    {
      key: 'strength-tracking',
      title: 'How do you prefer to track your strength improvement?',
      content:
        'Knowing how you measure progress helps Zing optimize your training and motivate you.',
      options: [
        {
          label: 'Heavier weights',
          value: 'heavierWeights',
          description: 'Increasing the weight you lift in your workouts.',
        },
        {
          label: 'New milestones',
          value: 'newMilestones',
          description: 'Achieving new personal records.',
        },
        {
          label: 'More repetitions',
          value: 'moreRepetitions',
          description: 'Performing a higher number of correct reps.',
        },
        {
          label: 'Compound exercises',
          value: 'compoundExercises',
          description: 'Improved performance in squats, deadlifts, and presses.',
        },
        { label: 'Other', value: 'other' },
      ],
      checkbox: true,
    },
    {
      key: 'fitness-feel',
      title: 'How do you hope to feel on your fitness journey?',
      options: [
        {
          label: 'Energized',
          value: 'energized',
          description: 'Increased energy and vitality.',
        },
        {
          label: 'Confident',
          value: 'confident',
          description: 'Self-assured, empowered and in control.',
        },
        {
          label: 'Relaxed',
          value: 'relaxed',
          description: 'Stress-free and comfortable in your body.',
        },
        {
          label: 'Accomplished',
          value: 'accomplished',
          description: 'A sense of achievement and progress.',
        },
      ],
      checkbox: true,
    },
    {
      key: 'workout-location',
      title: 'Choose your workout location',
      options: [
        {
          label: 'Home',
          value: 'home',
          image: imageMappings.home,
          description: 'Bodyweight exercises with little or no equipment',
        },
        {
          label: 'Gym',
          value: 'gym',
          image: imageMappings.gym,
          description: 'Machine and free-weight exercises',
        },
        {
          label: 'Mixed',
          value: 'mixed',
          image: imageMappings.mixed,
          description: 'Combination of gym and home workouts',
        },
      ],
    },
    {
      key: 'fitness-plan',
      title: 'Which elements of a fitness plan are important to you?',
      content: 'Select up to three options from the list below that you need the most.',
      options: [
        {
          label: 'Consistency',
          value: 'consistency',
          description: 'Ensuring regularity in your workout schedule.',
        },
        {
          label: 'Commitment',
          value: 'commitment',
          description: 'Remaining disciplined and sticking to your plan.',
        },
        {
          label: 'Workout guidance',
          value: 'workoutGuidance',
          description: 'Getting clear guidance on exercise technique.',
        },
        {
          label: 'Lifestyle changes',
          value: 'lifestyleChanges',
          description: 'Achieving a healthier and more active lifestyle.',
        },
        {
          label: 'Goal focus',
          value: 'goalFocus',
          description: 'Focusing on effective strategies for gaining muscle',
        },
        {
          label: 'Conveniences',
          value: 'conveniences',
          description: 'Workouts tailored for any environment.',
        },
      ],
      checkbox: true,
    },
    {
      key: 'preferred-time',
      title: 'When do you prefer to work out?',
      options: [
        { label: 'Morning', value: 'morning' },
        { label: 'Afternoon', value: 'afternoon' },
        { label: 'Evening', value: 'evening' },
        { label: 'At Different Times', value: 'atDifferentTimes' },
      ],
    },
    {
      key: 'dedicated-time',
      title: 'How much time can you dedicate to each workout?',
      options: [
        { label: '30 min or less', value: '<= 30' },
        { label: '45 min or less', value: '<= 45' },
        { label: 'One hour or so', value: '= 1' },
        { label: 'Over an hour', value: '> 1' },
      ],
    },
    {
      key: 'considered-sports',
      title: 'Do you have other sports that Zing should consider in your plan?',
      options: [
        { label: 'No', value: 'no' },
        {
          label: 'Cardio',
          value: 'cardio',
          description: 'Running, rowing, cycling',
        },
        {
          label: 'Flexibility',
          value: 'flexibility',
          description: 'Yoga, stretching, pilates',
        },
        {
          label: 'Martial Arts',
          value: 'martialArts',
          description: 'Boxing, MMA, kung fu',
        },
        {
          label: 'Game Sports',
          value: 'gameSports',
          description: 'Football, tennis, basketball',
        },
      ],
      checkbox: true,
    },
  ],
  [
    {
      key: 'body-type',
      title: 'Determine Your Body Type',
      content:
        'Your body type (or somatype) is based on skeletal frame, genetics, and body composition. Knowing helps me tailor your fitness program for the best results.',
      options: [
        {
          label: 'Ectomorph',
          value: 'ectomorph',
          image: imageMappings.ecto,
          description: 'Slim, light build, struggles to gain body fat and muscle mass.',
        },
        {
          label: 'Mesomorph',
          value: 'mesomorph',
          image: imageMappings.meso,
          description: 'Athletic, muscular build, quickly loses body fat and gains muscle mass.',
        },
        {
          label: 'Endomorph',
          value: 'endomorph',
          image: imageMappings.endo,
          description: 'Fuller figure, gains body fat quickly and struggles to lose it.',
        },
      ],
    },
    {
      key: 'gym-type',
      title: 'Which type of gym do you exercise at?',
      content: 'Zing will suggest a starter equipment list based on the type of gym you pick.',
      options: [
        {
          label: 'Large Gym',
          value: 'largeGym',
          description: 'Full fitness clubs such as Planet Fitness, 24 Hour Fitness, etc.',
        },
        {
          label: 'Small Gym',
          value: 'smallGym',
          description: 'Compact gym with limited equipment.',
        },
        {
          label: 'Garage Gym',
          value: 'garageGym',
          description: 'Barbell, squat rack, dumbbells, etc.',
        },
        {
          label: 'Home Gym',
          value: 'homeGym',
          description: 'Limited equipment such as dumbbells, bands and more.',
        },
      ],
    },
    {
      key: 'agains-aging-protection',
      title: 'Do you want workouts that protect you against aging?',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      key: 'train-time',
      title: 'How often did you train in the last 3 months?',
      content: 'This helps me design a plan to fit your fitness',
      options: [
        { label: 'Hardly ever', value: 'hardlyEver' },
        { label: 'Once or twice per week', value: 'oncetOrTwice' },
        { label: 'Three or four times per week', value: 'threeOrFour' },
        { label: 'Five or Six times per week', value: 'fiveOrSix' },
        { label: 'Everyday', value: 'everyday' },
      ],
    },
    {
      key: 'trained-with',
      title: 'Have you trained with any of these in the past?',
      options: [
        { label: 'Personal Trainers', value: 'personalTrainers' },
        { label: 'Fitness Apps', value: 'fitnessApps' },
        { label: 'Workout videos', value: 'workoutVideos' },
        { label: 'Self-tought', value: 'selfTought' },
        { label: 'None', value: 'none' },
      ],
      checkbox: true,
    },
    {
      key: 'improve-physique',
      title: 'Have you tried any of these to improve your physique?',
      content:
        'Zing adapts your plan based on the activities and behaviors you’ve adopted in the past.',
      options: [
        {
          label: 'Nutrition Changes',
          value: 'nutritionChanges',
          description: 'Restrictive diets, fasting, meal plans',
        },
        {
          label: 'Weight Training',
          value: 'weightTraining',
          description: 'Powerlifting, bodybuilding, circuit training',
        },
        {
          label: 'Cardio',
          value: 'cardio',
          description: 'Running, rowing, cycling',
        },
        {
          label: 'Bodyweight Training',
          value: 'bodyweightTraining',
          description: 'Yoga, calisthenics, pilates',
        },
        {
          label: 'Group Sports',
          value: 'groupSports',
          description: 'Football, tennis, basketball',
        },
        {
          label: 'Martial Arts',
          value: 'martialArts',
          description: 'Boxing, MMA, kung fu',
        },
        { label: 'Other', value: 'other' },
      ],
      checkbox: true,
    },
    {
      key: 'push-ups',
      title: 'How many push-ups can you do?',
      options: [
        { label: 'Fewer than 12', value: '<12' },
        { label: '13-20', value: '13-20' },
        { label: 'More than 20', value: '>20' },
        { label: "I don't know", value: 'unknown' },
      ],
    },
  ],
  [
    {
      key: 'fitness-level',
      title: 'What is your fitness level?',
      options: [
        {
          label: 'Beginner',
          value: 'beginner',
          image: imageMappings.beginner,
          description: 'I’m new to fitness',
        },
        {
          label: 'Intermediate',
          value: 'intermediate',
          image: imageMappings.intermediate,
          description: 'I work out from time to time',
        },
        {
          label: 'Advanced',
          value: 'advanced',
          image: imageMappings.advanced,
          description: 'I exercise regularly',
        },
      ],
    },
    {
      key: 'exercise-meaning',
      title: 'What does exercise mean in your life?',
      options: [
        {
          label: 'Necessary',
          value: 'necessary',
          image: imageMappings.necessary,
          description:
            'Exercising is challenging but essential, so I make time in my schedule for it.',
        },
        {
          label: 'Inconvenient',
          value: 'inconvenient',
          image: imageMappings.inconvinient,
          description:
            'I want to exercise, but something always comes up, and I struggle to do it consistently.',
        },
        {
          label: 'Enjoyable',
          value: 'enjoyable',
          image: imageMappings.enjoyable,
          description: 'Exercise is a natural part of my everyday life.',
        },
      ],
    },
    {
      key: 'stay-motivated',
      title: 'How would you describe your ability to stay motivated?',
      options: [
        {
          label: 'Steadfast',
          value: 'steadfast',
          image: imageMappings.steadfast,
          description: 'You rarely or never become unmotivated.',
        },
        {
          label: 'Resilient',
          value: 'resilient',
          image: imageMappings.resilient,
          description: 'You occasionally lose motivation but find it again easily.',
        },
        {
          label: 'Challenging',
          value: 'challenging',
          image: imageMappings.challenging,
          description: 'Maintaining motivation requires constant effort.',
        },
        {
          label: 'Fickle',
          value: 'fickle',
          image: imageMappings.fickle,
          description: 'You easily and quickly become unmotivated.',
        },
      ],
    },
    {
      key: 'cope-with-stress',
      title: 'How do you cope with stress?',
      content: 'These can be positive or negative coping mechanisms',
      options: [
        { label: 'Exercising', value: 'exercising' },
        { label: 'Being creative', value: 'beingCreative' },
        {
          label: 'Drinking alcohol or smoking',
          value: 'drinkingOrSmoking',
        },
        {
          label: 'Eating too much or too little',
          value: 'eatingTooMuchOrTooLittle',
        },
        { label: 'Getting outdoors', value: 'gettingOutdoors' },
      ],
      checkbox: true,
    },
    {
      key: 'help-lose-weight',
      title: `Have any fitness plans ever failed to help you Lose Weight?`,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      key: 'training-motivation',
      title: 'What made you want to start training?',
      content:
        'Understanding your reasons will empower me to motivate you on your fitness journey.',
      options: [
        {
          label: 'Job requirements',
          value: 'jobRequirements',
          description: 'Being in good shape is necessary for your job.',
        },
        {
          label: 'Observing inactivity in others',
          value: 'observingInactivity',
          description:
            'You witnessed the negative effects of a sedentary lifestyle on people in your life.',
        },
        {
          label: 'Visual indicators',
          value: 'visualIndicators',
          description:
            'You saw something in the mirror, on the scale, or in your body measurements.',
        },
        {
          label: 'Parental stamina',
          value: 'parentalStamina',
          description: 'You want to be fit enough to play with your kids.',
        },
        {
          label: 'Clothing fit',
          value: 'clothingFit',
          description: 'Your clothing doesn’t fit like it used to.',
        },
        {
          label: 'Health improvement',
          value: 'healthImprovement',
          description: 'You want to address specific health concerns.',
        },
        {
          label: 'Aging concerns',
          value: 'agingConcerns',
          description: 'You’re concerned about the effects of aging.',
        },
        {
          label: 'Current fitness levels',
          value: 'currentFitnessLevels',
          description: 'You’re dissatisfied with your fitness levels.',
        },
        {
          label: 'Sexual performance',
          value: 'sexualPerformance',
          description: 'You want to improve your sex life.',
        },
      ],
      checkbox: true,
    },
    {
      key: 'biggest-obstacle',
      title: "What's the biggest obstacle to your Muscle Gain goal?",
      content:
        'By anticipating challenges on your fitness journey, Zing can help you to overcome them.',
      options: [
        { label: 'Lack of Consistency', value: 'jobRequirements' },
        { label: 'Time constraints', value: 'observingInactivity' },
        { label: 'Lack of motivation', value: 'visualIndicators' },
        { label: 'Fatigue', value: 'parentalStamina' },
        { label: 'Lack of visible progress ', value: 'clothingFit' },
        { label: 'Plateaus in progress', value: 'healthImprovement' },
        { label: 'Lack of support', value: 'agingConcerns' },
        { label: 'Injuries or pain', value: 'currentFitnessLevels' },
      ],
      checkbox: true,
    },
  ],
  [
    {
      key: 'last-step',
      title: 'Last sub-step',
      content: 'Content for the last sub-step.',
    },
  ],
];

export const STEP_HEADER = [
  {
    title: 'Goals',
    steps: [0, 1, 2, 3, 4, 5],
  },
  {
    title: 'Workout preferences',
    steps: [6, 7],
  },
  {
    title: 'Workout settings',
    steps: [8],
  },
  {
    title: 'Workout preferences',
    steps: [9, 10, 11],
  },
  {
    title: 'Workout preferences',
    steps: [9, 10, 11],
  },
  {
    title: '',
    steps: [12],
  },
  {
    title: 'Personal details',
    steps: [12],
  },
];
