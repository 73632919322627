import { memo } from 'react';
import clsx from 'clsx';

import './style.scss';

const Button = ({ children, isPrimary, isSmall, ...rest }) => {
  return (
    <div className={clsx('button', { isPrimary, isSmall })} {...rest}>
      {children}
    </div>
  );
};

export default memo(Button);
