import { memo } from 'react';

import useTimer from 'utils/useTime';

const Timer = ({ deadline, withName }) => {
  const { hours, minutes, seconds } = useTimer(deadline);

  const dateInfo = {
    ...(hours === 0 && { Hours: hours }),
    Minutes: minutes,
    Seconds: seconds,
  };

  if (hours + minutes + seconds <= 0) {
    return (
      <p className="timer">
        <span>00{withName && <b>minutes</b>}</span>
        <span className="dots">:</span>
        <span>00{withName && <b>seconds</b>}</span>
      </p>
    );
  }

  return (
    <p className="timer">
      <span>
        {`${Math.floor(minutes)}`.padStart(2, '0')} {withName && <b>minutes</b>}
      </span>
      <span className="dots">:</span>
      <span>
        {`${Math.floor(seconds)}`.padStart(2, '0')} {withName && <b>seconds</b>}
      </span>
    </p>
  );
};

export default memo(Timer);
